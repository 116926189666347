import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/h4ad/Projects/opensource/H4ad.github.io/node_modules/@pauliescanlon/gatsby-theme-terminal/src/layouts/page-layout.js";
import { format } from 'date-fns';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const SiteMetaData = makeShortcode("SiteMetaData");
const Fragment = makeShortcode("Fragment");
const Heading = makeShortcode("Heading");
const Text = makeShortcode("Text");
const Divider = makeShortcode("Divider");
const Flex = makeShortcode("Flex");
const Box = makeShortcode("Box");
const Link = makeShortcode("Link");
const SourceTags = makeShortcode("SourceTags");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SiteMetaData mdxType="SiteMetaData">
      {siteMetadata => {
        const {
          name,
          description
        } = siteMetadata;
        return <Fragment mdxType="Fragment">
        <Heading variant='styles.h1' mdxType="Heading">{name}</Heading>
        <Text mdxType="Text">{description}</Text>
        </Fragment>;
      }}
    </SiteMetaData>
    <Divider mdxType="Divider" />
    <h2>{`About me`}</h2>
    <p>{`I'm Computer Engineer by `}<a parentName="p" {...{
        "href": "https://facens.br/home"
      }}>{`Facens`}</a>{` and currently I'm working as Software Engineer for `}<a parentName="p" {...{
        "href": "https://stefanini.com/en"
      }}>{`Stefanini North America`}</a>{`.`}</p>
    <p>{`Most of the time I work with `}<inlineCode parentName="p">{`NestJS`}</inlineCode>{` APIs and `}<inlineCode parentName="p">{`NodeJS`}</inlineCode>{` but I also create websites with `}<inlineCode parentName="p">{`React`}</inlineCode>{` and `}<inlineCode parentName="p">{`Angular`}</inlineCode>{`.
I also develop some apps with `}<inlineCode parentName="p">{`Ionic`}</inlineCode>{` and some APIs using `}<inlineCode parentName="p">{`ASP.NET Core`}</inlineCode>{`.`}</p>
    <p>{`I'm Node.js collaborator and I'm always looking for ways to improve the performance of the Node.js ecosystem.`}</p>
    <p>{`I'm also maintainer of `}<a parentName="p" {...{
        "href": "https://serverless-adapter.viniciusl.com.br/"
      }}>{`Serverless Adapter`}</a>{`, which is a library to connect any Node.js framework with any serverless environment.
In my spare time, I submit PRs for JS libraries like `}<inlineCode parentName="p">{`pg`}</inlineCode>{`, `}<inlineCode parentName="p">{`ohash`}</inlineCode>{`, `}<inlineCode parentName="p">{`NestJS`}</inlineCode>{`, `}<inlineCode parentName="p">{`pnpm`}</inlineCode>{` and many others in order to improve performance.`}</p>
    <Divider mdxType="Divider" />
    <Flex sx={{
      flexWrap: "wrap"
    }} mdxType="Flex">
  <Box sx={{
        width: ["100%", "100%", "55%"],
        mb: 3,
        pr: [0, 0, 4]
      }} mdxType="Box">
        <h2>{`Contact Info`}</h2>
  <Heading variant='styles.h3' sx={{
          mb: 0
        }} mdxType="Heading">GitHub</Heading>
  <Link href='https://github.com/H4ad/' variant='styles.a' mdxType="Link">https://github.com/H4ad/</Link>
  <Divider mdxType="Divider" />
  <Heading variant='styles.h3' sx={{
          mb: 0
        }} mdxType="Heading">LinkedIn</Heading>
  <Link href='https://www.linkedin.com/in/vinilourenco/' variant='styles.a' mdxType="Link">https://linkedin.com/in/vinilourenco/</Link>
  <Divider mdxType="Divider" />
  <Heading variant='styles.h3' sx={{
          mb: 0
        }} mdxType="Heading">Where I am</Heading>
  <Link href='https://pt.wikipedia.org/wiki/Sorocaba' variant='styles.a' mdxType="Link">Sorocaba, SP - Brazil</Link>
  <Divider mdxType="Divider" />
  </Box>
  <Box sx={{
        width: ["100%", "100%", "45%"],
        mb: 3
      }} mdxType="Box">
        <h2>{`Top Technologies`}</h2>
  <SourceTags filter="projects" mdxType="SourceTags">
    {tags => {
            return <Flex sx={{
              flexDirection: 'column'
            }} mdxType="Flex">
          {tags.splice(0, 5).sort((a, b) => b.percent - a.percent).map((tag, index) => {
                const {
                  name,
                  count,
                  percent
                } = tag;
                return <Box key={index} sx={{
                  color: 'muted',
                  mb: 3,
                  svg: {
                    fill: 'muted'
                  }
                }} mdxType="Box">
                  <Flex sx={{
                    lineHeight: 'normal'
                  }} mdxType="Flex">
                    <Text sx={{
                      color: 'secondary',
                      mr: 2,
                      mb: 0
                    }} mdxType="Text">{`${name}`}</Text>
                    <Text sx={{
                      color: 'muted'
                    }} mdxType="Text"> ({count} projects)</Text>
                  </Flex>
                </Box>;
              })}
        </Flex>;
          }}
  </SourceTags>
  </Box>
    </Flex>
    <Divider mdxType="Divider" />
    <Flex sx={{
      flexWrap: "wrap"
    }} mdxType="Flex">
  <Box sx={{
        width: ["100%", "50%", "33.333%"]
      }} mdxType="Box">
        <h2>{`Experience`}</h2>
  <Text mdxType="Text">[Stefanini] Senior Software Engineer</Text><br />
  <Text variant='styles.small' mdxType="Text">Jan 2023 – Today</Text>
  <Divider mdxType="Divider" />
  <Text mdxType="Text">[LIGA] Tech Lead</Text><br />
  <Text variant='styles.small' mdxType="Text">Dec 2021 – Jan 2023</Text>
  <Divider mdxType="Divider" />
  <Text mdxType="Text">[LIGA] Senior Software Engineer</Text><br />
  <Text variant='styles.small' mdxType="Text">Dec 2020 – Dec 2021</Text>
  <Divider mdxType="Divider" />
  <Text mdxType="Text">[LIGA] Mid. Software Engineer</Text><br />
  <Text variant='styles.small' mdxType="Text">Jan 2020 – Dec 2020</Text>
  <Divider mdxType="Divider" />
  <Text mdxType="Text">[LIGA] Junior Software Engineer</Text><br />
  <Text variant='styles.small' mdxType="Text">Jun 2019 – Jan 2020</Text>
  <Divider mdxType="Divider" />
  <Text mdxType="Text">[LIGA] Intern</Text><br />
  <Text variant='styles.small' mdxType="Text">Out 2018 - Jun 2019</Text>
  <Divider mdxType="Divider" />
  <Divider mdxType="Divider" />
  </Box>
  <Box sx={{
        width: ["100%", "50%", "33%"]
      }} mdxType="Box">
        <h2>{`Languages`}</h2>
  <Text mdxType="Text">Portuguese</Text><br />
  <Text variant='styles.small' mdxType="Text">Native</Text>
  <Divider mdxType="Divider" />
  <Text mdxType="Text">English</Text><br />
  <Text variant='styles.small' mdxType="Text">Working Proficiency</Text>
  <Divider mdxType="Divider" />
  </Box>
  <Box sx={{
        width: ["100%", "50%", "33%"]
      }} mdxType="Box">
        <h2>{`Studies`}</h2>
  <Text mdxType="Text">Computer Engineering</Text><br />
  <Text variant='styles.small' mdxType="Text">2018 - 2023</Text>
  <Divider mdxType="Divider" />
  </Box>
    </Flex>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      